import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from '../components/layout'
import { Link } from "gatsby"
import Image from '../images/Glyph/Vector.png'

export default function Template({data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  
  return (
    <Layout>
    
    <div className='container'>

        <Link to="/projects/"> <div className='backlink'><a><img src={Image} /> <span style={{padding:'3px'}}>BACK</span>  </a></div></Link>
        <GatsbyImage image={frontmatter.featuredImage.childImageSharp.gatsbyImageData} style={{height:'440px'}}/>

        <div className='container_project_body' >
        <h1 >{frontmatter.title}</h1>  
        <p style={{color:``}}>{frontmatter.date}</p>

        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        </div>
    </div>
    
    </Layout>
  )
}

export const pageQuery = graphql`
{
    markdownRemark {
      html
      frontmatter {
        category
        date(formatString: "MMMM DD YYYY")
        link
        slug
        tags
        title
        featuredImage {
          childImageSharp {
            gatsbyImageData( placeholder: DOMINANT_COLOR)
          }
        }
      }
    }
  }
`


